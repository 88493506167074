.App {
  text-align: center;
  box-sizing: border-box;
  background-color: #1e2433;
  height: 100vh;
}

.App-container {
  display: flex;
  flex-wrap: wrap;
  color: white;
  font-size: calc(8px + 2vmin);
  font-family: "Abril Fatface", serif;
}

.App-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
}

.App-title h1 {
  text-align: center;
  padding-right: 50px;
}

.App-logo {
  width: 200px;
  height: 200px;
}

.App-description {
  pointer-events: none;
  margin-left: 20px;
  width: 45%;
}

.App-poll {
  border: none;
  width: 45%;
  min-height: 500px;
}

@media (max-width: 600px) {
  .App-description, .App-poll {
    width: 100%;
  }

  .App-container {
    font-size: calc(5px + 2vmin);
  }

  .App-description {
    display: none;
  }
}
